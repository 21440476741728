var spinnerAjaxModal = '<div style="text-align: center; padding: 20px;"><span class="fa fa-spin fa-spinner fa-2x" style="margin: 20px auto;"></span></div>';

document.addEventListener("DOMContentLoaded", function (event) {
  ajaxLoad('.js-ajax');
  ajaxModal('.js-ajax-modal');
  iframeModal('.js-iframe-modal');
  alertModalTrigger('.js-alert');
  confirmModalTrigger('.js-confirm');
  confirmModalTrigger('.js-remove');
  toggler();

  $('*[data-toggle="modal"]').click(function () {
    fixBackDrop($(this));
  });
});

function fixBackDrop($this) {
  if ($(window).width() <= 767) {
    setTimeout(function () {
      var id = $this.data('target');
      var $modal = $(id).closest('.modal');
      var $backdrop = $('.modal-backdrop');
      $backdrop.remove();
      $modal.before($backdrop);
      $modal.animate({top: $('body').position().top}, 100);
    }, 500);
  }
}

function alertModalTrigger(selector) {
  if (!selector) {
    selector = '.js-modal';
  }
  $(document).on("click", selector, function () {
    var $this = $(this);
    alertModal($this.data('message'), $this.data('message-ok'), $this.data('modal'), $this.data('callback'));
  });
}

function confirmModalTrigger(selector) {
  if (!selector) {
    selector = '.js-modal';
  }
  $(document).on("click", selector, function () {
    var $this = $(this);
    confirmModal($this.data('message'), $this.data('message-ok'), $this.data('message-ko'), $this.data('url'), $this.data('modal'), $this.data('callback'), $this.data('target'));
  });
}

function alertModal(message, messageOk, modalId, callback) {
  if (!message) {
    return alert('OIMMEI! Messaggio mancante');
  }
  if (messageOk) {
    if ($('#alert-message').length == 1) {
      var html = '';
      if (modalId) {
        html = $('#' + modalId).html();
      } else {
        html = $('#modalAlert').html();
      }
      html = html.format({
        message: message,
        message_ok: messageOk,
      });
      modalOpen(html, callback);
    } else {
      alert(message);
    }
  } else {
    return alert('OIMMEI! Testo pulsante mancante');
  }
}

function oiConfirm(message, messageOk, messageKo, callbackOk, callbackKo, modalId) {
  if (!message) {
    return alert('OIMMEI! Messaggio mancante');
  }
  if (messageOk && messageKo) {
    if (!modalId) {
      modalId = 'oiConfirm';
    }
    if ($('#' + modalId).length == 1) {
      var html = $('#' + modalId).html();
      html = html.format({
        message: message,
        message_ok: messageOk,
        message_ko: messageKo,
      });
      
      var callback = function() {
        $('#alertModal .btn-ok').click(function () {
          if(callbackOk) {
            callbackOk();
          }
        });
        $('#alertModal .btn-ko').click(function () {
          if(callbackKo) {
            callbackKo();
          }
        });
      }
      
      modalOpen(html, callback);
    } else {
      if (confirm(message)) {
        if(callbackOk) {
          callbackOk();
        }
      } else {
        if(callbackKo) {
          callbackKo();
        }
      }
    }
  } else {
    return alert('OIMMEI! Testi pulsanti mancanti');
  }
}

function confirmModal(message, messageOk, messageKo, url, modalId, callback, target) {
  if (!url) {
    return alert('OIMMEI! Url mancante');
  }
  if (!message) {
    return alert('OIMMEI! Messaggio mancante');
  }
  if (messageOk && messageKo) {
    if ($('#alert-message').length == 1) {
      var html = '';
      if (modalId) {
        html = $('#' + modalId).html();
      } else {
        html = $('#modalConfirm').html();
      }
      html = html.format({
        message: message,
        url: url,
        message_ok: messageOk,
        message_ko: messageKo,
        target: target
      });
      modalOpen(html, callback);
    } else {
      if (confirm(message)) {
        window.location = url;
      }
    }
  } else {
    return alert('OIMMEI! Testi pulsanti mancanti');
  }
}

function ajaxModal(selector) {
  if (!selector) {
    selector = '.js-ajax-modal';
  }
  $(document).on("click", selector, function () {
    var $this = $(this);
    var callback = $this.data('callback');
    if (!callback) {
      callback = $this.attr('callback')
    }
    if ($this.data('target')) {
      $($this.data('target') + " .modal-body").html(spinnerAjaxModal);
      $($this.data('target')).modal('show');
    } else {
      modalOpen('<div class="modal-body">' + spinnerAjaxModal + '</div>');
    }
    $.get($this.data("href"), function (html) {
      if ($this.data('target')) {
        $($this.data('target') + " .modal-body").html(html);
        if (callback) {
          try {
            callback();
          } catch (e) {
            eval(callback + '();');
          }
        }
      } else {
        modalOpen(html, callback, true);
      }
    });
  });
}

function iframeModal(selector) {
  if (!selector) {
    selector = '.js-ajax-modal';
  }
  $(document).on("click", selector, function () {
    var $this = $(this);
    var callback = $this.data('callback');
    if (!callback) {
      callback = $this.attr('callback')
    }
    var iframe = '<iframe src="' + $this.data('href') + '" width="100%" height="200px"></iframe>';
    if ($this.data('target')) {
      $($this.data('target') + " .modal-body").html(iframe);
    } else {
      modalOpen('<div class="modal-body">' + iframe + '</div>');
    }
  });
}

function ajaxLoad(selector, noSpin) {
  if (!selector) {
    selector = '.js-ajax';
  }

  $(document).on("click", selector, function () {
    var $this = $(this);
    if (!$this.data('target')) {
      return alert('OIMMEI! Url target');
    }
    var callback = $this.data('callback');
    var precallback = $this.data('precallback');
    if(noSpin) {
      $($this.data('target')).html(spinnerAjaxModal);
    }
    $.get($this.data("href"), function (html) {
      if(precallback) {
        if (is_string(precallback)) {
          eval(precallback + "(html)");
        } else {
          precallback(html);
        }
      } else {
        $($this.data('target')).html(html);
      }
      if (callback) {
        if (is_string(callback)) {
          eval(callback + "()");
        } else {
          callback();
        }
      }
    });
    return false;
  });
}

function modalOpen(html, callback, notOpen) {
  $('#alert-message').html(html);
  if (!notOpen) {
    $('#alertModal').modal('show');
  }
  if (callback) {
    if (is_string(callback)) {
      eval(callback + "()");
    } else {
      callback();
    }
  }
}

function toggablePassword(selector) {
  var $inputPwd = $(selector);
  var $formGroup = $inputPwd.closest('.form-group');
  var $inputText = $('<input>').addClass('form-control').attr('type', 'text').css('display', 'none');
  var $inputGroup = $('<div></div>').addClass('input-group').addClass('mb-2');
  var $eye = $('<i></i>').addClass('fa fa-eye');
  var $box = $('<div></div>').addClass('input-group-append');
  var $boxInner = $('<div></div>').addClass('input-group-text');
  $boxInner.append($eye);
  $box.append($boxInner);
  $inputGroup.append($inputPwd).append($inputText).append($box);
  $formGroup.append($inputGroup);
  $box.click(function () {
    $inputPwd.toggle();
    $inputText.toggle();
    $eye.toggleClass('fa-eye fa-eye-slash');
  });
  $inputPwd.keyup(function () {
    $inputText.val($(this).val());
  });
  $inputText.keyup(function () {
    $inputPwd.val($(this).val());
  });
}

function reloadPageCallback() {
  window.location.reload();
}

function stickyTable(selector) {
  var thead = $(".table-sticky thead");
  var n_thead = $("<thead class='table-fixed-header'></thead>");
  thead.find("tr").each(function () {
    var tr = $(this);
    var n_tr = $("<tr></tr>");
    copyWidth(tr, n_tr);
    tr.children().each(function () {
      var tag = $(this).prop('nodeName');
      var th = $('<' + tag + '></' + tag + '>');
      th.html($(this).html());
      th.attr('style', $(this).attr('style'));
      th.attr('class', $(this).attr('class'));
      th.attr('rowspan', $(this).attr('rowspan'));
      th.attr('colspan', $(this).attr('colspan'));
      copyWidth($(this), th);
      n_tr.append(th);
    });
    n_thead.append(n_tr);
  });
  thead.after(n_thead);
  var status = 'unknow';
  $(window).scroll(function () {
    if (selector == parseInt(selector)) {
      h = selector;
    } else if (!selector) {
      h = 0;
    } else {
      h = $(selector).height();
    }
    if (thead.position().top - $(window).scrollTop() < h && thead.closest('table').height() + (thead.position().top - $(window).scrollTop()) - thead.height() > h) {
      if (status != 'sticked') {
        status = 'sticked';
        n_thead.show();
        $(window).resize();
      }
    } else {
      if (status != 'table') {
        status = 'table';
        n_thead.hide();
      }
    }
  });
  $(window).scroll();

  //$(window).resize();
  function copyWidth(source, target) {
    target.css('width', (source.width() + parseInt(source.css('padding-left')) + parseInt(source.css('padding-right'))) + 'px');
    $(window).resize(function () {
      target.css('width', (source.width() + parseInt(source.css('padding-left')) + parseInt(source.css('padding-right'))) + 'px');
    });
  }
}

function toggler(selector) {
  if (!selector) {
    selector = '*[data-toggle]';
  }
  console.log('TOGGLER: ' + selector);
  $(selector).click(function () {
    $($(this).data('toggle')).slideToggle();
  });
}

function is_string(input) {
  return typeof(input) === 'string';
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

global.fixBackDrop = fixBackDrop;
global.alertModalTrigger = alertModalTrigger;
global.confirmModalTrigger = alertModalTrigger;
global.alertModal = alertModal;
global.confirmModal = confirmModal;
global.ajaxModal = ajaxModal;
global.iframeModal = iframeModal;
global.ajaxLoad = ajaxLoad;
global.oiConfirm = oiConfirm;
global.modalOpen = modalOpen;
global.toggablePassword = toggablePassword;
global.reloadPageCallback = reloadPageCallback;
global.stickyTable = stickyTable;
global.toggler = toggler;
global.debounce = debounce;